import styled from "styled-components"
import Img from "gatsby-image"
import { motion } from "framer-motion"

export const BGImg = styled.img`
  position: absolute;
  right: 0;
  width: 50%;
  top: 20%;
`

export const Banner = styled.section`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 599px) {
    height: auto;
    margin: 100px 0;
  }
`

export const TitleContainer = styled.div`
  color: ${({ theme: { logoText } }) => logoText};
  font-size: 3.5rem;
  font-weight: 500;
  max-width: 70%;
  margin-bottom: 50px;

  @media (max-width: 599px) {
    font-size: 2rem;
  }

  @media (max-width: 900px) {
    max-width: 90%;
  }
`

export const DropDownContainer = styled.div`
  margin: 0 5px;
  cursor: pointer;
`

export const DescriptionContainer = styled.div`
  display: flex;
`

export const Description = styled.p`
  color: ${({ theme: { logoText } }) => logoText};
  font-weight: 200;
  font-size: 1.3rem;

  @media (max-width: 599px) {
    font-size: 1rem;
  }
`

export const DescriptionSelected = styled.p`
  color: ${({ theme: { logoText } }) => logoText};
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: underline;

  @media (max-width: 599px) {
    font-size: 1rem;
  }
`

export const WorksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 90px;
  margin-bottom: 100px;
  justify-items: center;
  align-items: center;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const WorkCard = styled(motion.div)`
  height: 400px;
  width: 240px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: ${({ isEven }) => (isEven ? "50px" : "0")};

  @media (max-width: 599px) {
    margin-top: 0;
  }
`

export const WorkCardImg = styled(Img)`
  height: 400px;
  width: 240px;
`
