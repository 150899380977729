import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"

import SEO from "./../components/Seo"
import Layout from "./../components/layout"
import { Container } from "./../styles/GlobalStyles"

import Clients from "../components/Clients"
import Footer from "../components/Footer"

import {
  Banner,
  TitleContainer,
  Description,
  DescriptionContainer,
  DropDownContainer,
  DescriptionSelected,
  WorksContainer,
  WorkCard,
  WorkCardImg,
} from "./../styles/works"

const works = [
  {
    id: "p1",
    videoLink: "pUsPdhf_RPo",
  },
  {
    id: "p2",
    videoLink: "saigF3gYTwc",
  },
  {
    id: "p3",
    videoLink: "gBI98KbIKpI",
  },
  {
    id: "p4",
    videoLink: "2WclNScNVQM",
  },
  {
    id: "p5",
    videoLink: "Pxm_VOT8Hd8",
  },
  {
    id: "p6",
    videoLink: "NrDOI2kBXDA",
  },
  {
    id: "p7",
    videoLink: "5083WMoVvQA",
  },
  {
    id: "p8",
    videoLink: "IEJ4zztyx8E",
  },
]

const DropDown = props => {
  const { selected } = props
  return (
    <DropDownContainer>
      <DescriptionSelected>{selected}</DescriptionSelected>
    </DropDownContainer>
  )
}

const Works = props => {
  const data = useStaticQuery(graphql`
    query {
      p1: file(relativePath: { eq: "p1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p2: file(relativePath: { eq: "p2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p3: file(relativePath: { eq: "p3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p4: file(relativePath: { eq: "p4.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p5: file(relativePath: { eq: "p5.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p6: file(relativePath: { eq: "p6.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p7: file(relativePath: { eq: "p7.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      p8: file(relativePath: { eq: "p8.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400, maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Our Works" pathname={props.location.pathname} />
      <Container>
        <Banner>
          <TitleContainer>
            Our work is the presentation of our capabilities.
          </TitleContainer>
          <DescriptionContainer>
            <Description>
              Some of the projects we've done for our clients or as an hobby
            </Description>
          </DescriptionContainer>
        </Banner>
        <WorksContainer>
          {works.map((_w, index) => {
            return (
              <Link
                to={`/VideoModal?videosrc=${_w.videoLink}`}
                state={{
                  noScroll: true,
                }}
                asModal
              >
                <WorkCard
                  isEven={index % 2 !== 0}
                  animate={{
                    y: index % 2 !== 0 ? [5, -5, 5] : [-5, 5, -5],
                  }}
                  transition={{
                    type: "tween",
                    loop: 10000,
                    ease: "linear",
                    duration: 2,
                  }}
                >
                  <WorkCardImg fluid={data[_w.id].childImageSharp.fluid} />
                </WorkCard>
              </Link>
            )
          })}
        </WorksContainer>
      </Container>
      <Clients />
      <Footer />
    </Layout>
  )
}

export default Works
